//
// Component: Sidebar Mini
//

// Logo style
.logo-xs,
.logo-xl {
  opacity: 1;
  position: absolute;
  visibility: visible;

  &.brand-image-xs {
    left: 18px;
    top: 12px;
  }

  &.brand-image-xl {
    left: 12px;
    top: 6px;
  }
}

.logo-xs {
  opacity: 0;
  visibility: hidden;

  &.brand-image-xl {
    left: 16px;
    top: 8px;
  }
}

.brand-link {
  &.logo-switch {
    &::before {
      content: "\00a0";
    }
  }
}

// Add sidebar-mini class to the body tag to activate this feature
.sidebar-mini {
  @include media-breakpoint-up(lg) {
    @include sidebar-mini-breakpoint ();
  }
}
@include media-breakpoint-down(md) {
  .sidebar-mini.sidebar-collapse .main-sidebar {
    box-shadow: none !important;
  }
}

.sidebar-mini-md {
  @include media-breakpoint-up(md) {
    @include sidebar-mini-breakpoint ();
  }
}
@include media-breakpoint-down(sm) {
  .sidebar-mini-md.sidebar-collapse .main-sidebar {
    box-shadow: none !important;
  }
}

.sidebar-mini-xs {
  @include media-breakpoint-up(xs) {
    @include sidebar-mini-breakpoint ();
  }
}

.sidebar-mini,
.sidebar-mini-md,
.sidebar-mini-xs {
  .main-sidebar {
    .nav-child-indent .nav-treeview {
      .nav-link {
        width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - 1rem);
      }

      .nav-treeview {
        .nav-link {
          width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - 2rem);
        }

        .nav-treeview {
          .nav-link {
            width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - 3rem);
          }

          .nav-treeview {
            .nav-link {
              width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - 4rem);
            }

            .nav-treeview {
              .nav-link {
                width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - 5rem);
              }
            }
          }
        }
      }
    }
    .nav-legacy {
      .nav-link {
        width: $sidebar-width;
      }
      &.nav-child-indent {
        .nav-treeview {
          .nav-link {
            width: calc(#{$sidebar-width} - 1rem);
          }

          .nav-treeview {
            .nav-link {
              width: calc(#{$sidebar-width} - 1rem - 1rem);
            }

            .nav-treeview {
              .nav-link {
                width: calc(#{$sidebar-width} - 1rem - 2rem);
              }

              .nav-treeview {
                .nav-link {
                  width: calc(#{$sidebar-width} - 1rem - 3rem);
                }

                .nav-treeview {
                  .nav-link {
                    width: calc(#{$sidebar-width} - 1rem - 4rem);
                  }
                }
              }
            }
          }
        }
      }
    }
    .nav-flat {
      .nav-link {
        width: $sidebar-width;
      }
      &.nav-child-indent {
        .nav-treeview {
          .nav-link {
            width: calc(#{$sidebar-width});
          }

          .nav-treeview {
            .nav-link {
              width: calc(#{$sidebar-width} - .2rem);
            }

            .nav-treeview {
              .nav-link {
                width: calc(#{$sidebar-width} - .2rem * 2);
              }

              .nav-treeview {
                .nav-link {
                  width: calc(#{$sidebar-width} - .2rem * 3);
                }

                .nav-treeview {
                  .nav-link {
                    width: calc(#{$sidebar-width} - .2rem * 4);
                  }
                }
              }
            }
          }
        }
      }
    }
    .nav-child-indent.nav-compact .nav-treeview {
      .nav-link {
        width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - .5rem);
      }

      .nav-treeview {
        .nav-link {
          width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - 1rem);
        }

        .nav-treeview {
          .nav-link {
            width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - 1.5rem);
          }

          .nav-treeview {
            .nav-link {
              width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - 2rem);
            }

            .nav-treeview {
              .nav-link {
                width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2 - 2.5rem);
              }
            }
          }
        }
      }
    }
    .nav-child-indent.nav-legacy.nav-compact {
      .nav-link {
        width: $sidebar-width;
      }
      .nav-treeview {
        .nav-link {
          width: calc(#{$sidebar-width} - .5rem);
        }

        .nav-treeview {
          .nav-link {
            width: calc(#{$sidebar-width} - .5rem * 2);
          }

          .nav-treeview {
            .nav-link {
              width: calc(#{$sidebar-width} - .5rem * 3);
            }

            .nav-treeview {
              .nav-link {
                width: calc(#{$sidebar-width} - .5rem * 4);
              }

              .nav-treeview {
                .nav-link {
                  width: calc(#{$sidebar-width} - .5rem * 5);
                }
              }
            }
          }
        }
      }
    }
    .nav-link {
      width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2);
      @include transition(width $transition-fn $transition-speed);
    }
  }
}

.sidebar-collapse {
  &.sidebar-mini,
  &.sidebar-mini-md,
  &.sidebar-mini-xs {
    .main-sidebar {

      // Hide the sidebar search results when mini mode is active.
      .sidebar-search-results {
        display: none;
      }

      .nav-sidebar {
        .nav-link {
          width: $sidebar-mini-width - $sidebar-padding-x * 2;
        }

        &.nav-flat,
        &.nav-legacy {
          .nav-link {
            width: $sidebar-mini-width;
          }
        }
        &.nav-child-indent.nav-compact {
          .nav-treeview {
            padding-left: 0 !important;
            margin-left: 0 !important;
          }

          .nav-link {
            width: calc(#{$sidebar-mini-width} - #{$sidebar-padding-x} * 2);
          }
        }
      }

      &.sidebar-focused,
      &:hover {
        .nav-link {
          width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2);
        }
        .nav-header {
          display: inline-block;
        }

        // Show sidebar search when hover/focus on the sidebar mini mode.
        &:not(.sidebar-no-expand) {
          .form-control-sidebar {
            display: inline-block;
          }
          .form-control-sidebar ~ .input-group-append {
            display: flex;
          }
          .sidebar-search-open .sidebar-search-results {
            display: inline-block;
          }
        }

        .nav-child-indent {
          .nav-link {
            width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2);
          }
        }
        .nav-legacy {
          .nav-link {
            width: $sidebar-width;
          }
          &.nav-child-indent {
            .nav-treeview {
              .nav-link {
                width: calc(#{$sidebar-width} - 1rem);
              }

              .nav-treeview {
                .nav-link {
                  width: calc(#{$sidebar-width} - 1rem - 1rem);
                }

                .nav-treeview {
                  .nav-link {
                    width: calc(#{$sidebar-width} - 1rem - 2rem);
                  }

                  .nav-treeview {
                    .nav-link {
                      width: calc(#{$sidebar-width} - 1rem - 3rem);
                    }

                    .nav-treeview {
                      .nav-link {
                        width: calc(#{$sidebar-width} - 1rem - 4rem);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .nav-flat {
          .nav-link {
            width: $sidebar-width;
          }
          &.nav-child-indent {
            .nav-treeview {
              .nav-link {
                width: calc(#{$sidebar-width});
              }

              .nav-treeview {
                .nav-link {
                  width: calc(#{$sidebar-width} - .2rem);
                }

                .nav-treeview {
                  .nav-link {
                    width: calc(#{$sidebar-width} - .2rem * 2);
                  }

                  .nav-treeview {
                    .nav-link {
                      width: calc(#{$sidebar-width} - .2rem * 3);
                    }

                    .nav-treeview {
                      .nav-link {
                        width: calc(#{$sidebar-width} - .2rem * 4);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .nav-child-indent.nav-compact {
          .nav-link {
            width: calc(#{$sidebar-width} - #{$sidebar-padding-x} * 2);
          }
        }
        .nav-child-indent.nav-legacy.nav-compact {
          .nav-link {
            width: $sidebar-width;
          }
          .nav-treeview {
            .nav-link {
              width: calc(#{$sidebar-width} - .5rem);
            }

            .nav-treeview {
              .nav-link {
                width: calc(#{$sidebar-width} - .5rem * 2);
              }

              .nav-treeview {
                .nav-link {
                  width: calc(#{$sidebar-width} - .5rem * 3);
                }

                .nav-treeview {
                  .nav-link {
                    width: calc(#{$sidebar-width} - .5rem * 4);
                  }

                  .nav-treeview {
                    .nav-link {
                      width: calc(#{$sidebar-width} - .5rem * 5);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sidebar-no-expand.main-sidebar.sidebar-focused,
    .sidebar-no-expand.main-sidebar:hover {
      width: $sidebar-mini-width;

      .nav-header {
        display: none;
      }

      .brand-link {
        width: $sidebar-mini-width !important;
      }

      .user-panel .image {
        float: none !important;
      }

      .logo-xs {
        animation-name: fadeIn;
        animation-duration: $transition-speed;
        animation-fill-mode: both;
        visibility: visible;
      }

      .logo-xl {
        animation-name: fadeOut;
        animation-duration: $transition-speed;
        animation-fill-mode: both;
        visibility: hidden;
      }

      .nav-sidebar.nav-child-indent .nav-treeview {
        padding-left: 0;
      }

      .sidebar {
        .brand-text,
        .user-panel > .info,
        .nav-sidebar .nav-link p {
          margin-left: -10px;
          animation-name: fadeOut;
          animation-duration: $transition-speed;
          animation-fill-mode: both;
          visibility: hidden;
          width: 0;
        }

        .nav-sidebar > .nav-item .nav-icon {
          margin-right: 0;
        }
      }
    }
  }
}

.nav-sidebar {
  position: relative;

  &:hover {
    overflow: visible;
  }
}

.sidebar-form,
.nav-sidebar > .nav-header {
  overflow: hidden;
  text-overflow: clip;
}

.nav-sidebar .nav-item > .nav-link {
  position: relative;

  > .float-right {
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
  }
}

.sidebar .nav-link p,
.main-sidebar .brand-text,
.main-sidebar .logo-xs,
.main-sidebar .logo-xl,
.sidebar .user-panel .info {
  @include transition(margin-left $transition-speed linear, opacity $transition-speed ease, visibility $transition-speed ease);
}
